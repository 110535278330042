.DarkMode {
  .darklitemode {
    margin: 0.3rem 0.9rem 0;
    .checkbox {
      opacity: 0;
      position: absolute;
    }

    .label {
      width: 30px;
      height: 15px;
      background-color: var(--color-valhalla);
      border: 1px solid var(--color-white);
      display: flex;
      border-radius: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      position: relative;
      transform: scale(1.5);
    }

    .ball {
      width: 9px;
      height: 9px;
      background-color: var(--color-white);
      position: absolute;
      top: 2px;
      left: 3px;
      border-radius: 50%;
      transition: transform 0.2s linear;
    }

    .checkbox:checked + .label .ball {
      transform: translateX(14px);
    }
    // .fa-moon {
    //   color: #ffc107;
    //   font-size: 12px;
    // }

    // .fa-sun {
    //   color: #ffffff;
    //   font-size: 12px;
    // }
  }
}

body, .wrapper .main-container {
  scrollbar-width: auto;
  scrollbar-color: #f0c418 #140e38;
}

body::-webkit-scrollbar, .wrapper .main-container::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track, .wrapper .main-container::-webkit-scrollbar-track {
  background: #140e38;
}

body::-webkit-scrollbar-thumb, .wrapper .main-container::-webkit-scrollbar-thumb {
  background-color: #f0c418;
  border-radius: 13px;
  border: 0px solid #ffffff;
}
.form-check {
  .form-check-input {
    background-color: var(--color-haiti);
    box-shadow: none;
    &:checked {
      background-color: var(--color-bilobaflower);
      border-color: var(--color-bilobaflower);
      box-shadow: none;
  }
  }
}
.btn {
  padding: 0.5rem 1.5rem;
  font-size: var(--font-size-base);
  font-family: var(--font-family-base);
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.btn-danger {
  color: #fff;
  background-color: var(--color-dark-red) !important;
  border-color: var(--color-dark-red) !important;
}

@mixin main-btn {
  background-image: url(../assets/image/btn-bg.webp);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: capitalize;
  border: 0;
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    color: var(--color-white);
  }
}

.btn-link {
  color: var(--color-white);
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &.active,
  &:active,
  &:focus,
  &.active:focus {
    color: var(--color-bilobaflower);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}

.form-control {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: var(--color-bilobaflower);
  background-color: var(--color-valhalla);
  border: 1px solid var(--color-valhalla);
  &:focus,
  &:hover {
    color: var(--color-bilobaflower);
    background-color: #2d226e;
    border-color: var(--color-bilobaflower);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(147 95 207 / 29%);
  }
}
.form-control::placeholder {
  color: var(--color-bilobaflower);
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: var(--color-bilobaflower);
}
.form-control::-ms-input-placeholder {
  color: var(--color-bilobaflower);
}
@media (max-width: 767.98px) {
  .sell-btn {
    padding: 0.5rem 0.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    min-width: 65px;
    text-transform: capitalize;
    font-size: 10px;
  }
}
