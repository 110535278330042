.Logpage-btn {
  @include main-btn;
  padding: 0.75rem 0.75rem;
  font-size: 14px;
  min-width: 190px;
  color: var(--color-white);
}

.sell-btn {
  @include main-btn;
  padding: 0.5rem 0.5rem;
  font-size: 13px;
  min-width: 80px;
}

.start-training {
  @include main-btn;
  min-width: 100px;
  padding: 0.5rem 1.75rem;
}
.defaultbtn {
  @include main-btn;
  padding: 0.5rem 1.75rem;
}
.sidebar-btn {
  padding: 5px;
  color: var(--color-bilobaflower);
  border-radius: 5px;
  border: 1px solid var(--color-bilobaflower);
  transition: all 0.3s;
  cursor: pointer;
  svg {
    vertical-align: text-bottom !important;
  }
  &:hover,
  &:focus {
    border-color: var(--color-yellow);
    color: var(--color-white);
  }
}

.filterbtn {
  background-color: var(--color-valhalla);
  border-color: var(--color-valhalla);
  &:hover,
  &:focus {
    background-color: var(--color-valhalla);
    border-color: var(--color-valhalla);
  }
}

.filtererres-btn {
  background-color: var(--color-bilobaflower);
  border-color: var(--color-bilobaflower);
  box-shadow: none;
  outline: none;
  &:hover,
  &:focus {
    background-color: var(--color-bilobaflower);
    border-color: var(--color-bilobaflower);
    box-shadow: none;
    outline: none;
  }
}
